"use client";

import type { ReactNode } from "react";
export interface IAWInfoLineProps {
  prompt: string;
  content: ReactNode;
}

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@trident/ui/tooltip";
import { CircleHelp } from "lucide-react";
import { cn } from "@trident/ui";

export default function AWInfoLine({
  prompt,
  content,
  largePrompt = false,
}: {
  prompt: string;
  content: ReactNode;
  largePrompt?: boolean;
}) {
  return (
    <div className="flex items-center gap-2">
      <p
        className={cn(
          largePrompt ? "text-lg leading-6 tracking-[-0.09px]" : "text-sm",
          "text-light-teal-0",
        )}
      >
        {prompt}
      </p>
      <TooltipProvider>
        <Tooltip delayDuration={200}>
          <TooltipTrigger type="button">
            <CircleHelp
              size={16}
              className="cursor-pointer text-light-teal-0"
            />
          </TooltipTrigger>
          <TooltipContent
            align="start"
            className="max-w-[320px] rounded border-[1px] border-solid border-dark-teal-5 bg-white p-[10px] text-sm text-dark-teal-5"
          >
            {content}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}
