"use client";

import { cn } from "@trident/ui";
import { ChevronLeft } from "lucide-react";
import { Button } from "@trident/ui/button";
import { useGetProgress } from "~/stores/main-form-store";
import { useEffect, useState } from "react";
import { useParams, useRouter } from "next/navigation";

export default function InsuranceApplicationDialog({
  children,
  title,
  disableBackButton,
  disableProgressBar,
  size = "default",
}: {
  size?: "default" | "md" | "lg";
  title: string;
  children: React.ReactNode;
  disableBackButton?: boolean;
  disableProgressBar?: boolean;
}) {
  // fixes the: Warning: Expected server HTML to contain a matching <div> in <div>.
  const [hasMounted, setHasMounted] = useState(false);
  const router = useRouter();
  const progress = useGetProgress();
  const { form: currentStep } = useParams<{ form: string }>();

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  const routerPrevStep = () => {
    if (disableBackButton) return;
    router.back();
  };

  return (
    <div
      className={cn(
        size === "md" ? "max-w-[750px]" : "max-w-[1050px]",
        "flex h-fit w-full flex-col items-center justify-center border-2 border-solid border-grayscale-6 bg-grayscale-white shadow-[0px_0px_33px_rgba(0,0,0,0.09)]",
      )}
    >
      <div className="grid h-[48px] w-full grid-cols-[44px_1fr_44px] place-items-center items-center">
        <Button
          variant="ghost"
          size="icon"
          className={cn(
            currentStep === "welcome" && "hidden",
            disableBackButton && "hidden",
          )}
          onClick={routerPrevStep}
        >
          <ChevronLeft size={20} />
        </Button>
        {!disableProgressBar && progress > 0 && progress < 1 ? (
          <div className="relative flex h-[8px] w-[600px] items-center justify-between rounded-[4px] bg-[#E0E3E6] px-[2px]">
            <div
              className="absolute left-0 top-0 h-full rounded-[4px] bg-light-teal-2"
              style={{
                width: `${100 * progress}%`,
                transition: "0.79s cubic-bezier(.47,-0.04,.06,1.01)",
              }}
            />
            {[...Array(5).keys()].map((i) => (
              <div
                key={i}
                className={`h-[4px] w-[4px] rounded-full ${
                  progress === 1 || i / 4 < progress
                    ? "bg-[#E0E3E6]"
                    : "bg-dark-teal-0"
                } z-10`}
              />
            ))}
          </div>
        ) : null}
      </div>
      <div className="box-border flex h-[50px] w-full items-center border-y-2 border-y-grayscale-6 bg-[#F0F1F1] px-6 font-mono text-xl font-medium uppercase text-dark-teal-2">
        {title}
      </div>
      <div className="container m-auto flex h-full min-h-[490px] w-full justify-center 2xl:min-h-[525px]">
        {children}
      </div>
    </div>
  );
}
