import { cn } from "@trident/ui";

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "animate-pulse rounded-md bg-grayscale-7 dark:bg-foreground",
        className,
      )}
      {...props}
    />
  );
}

export { Skeleton };
