import InsuranceApplicationDialog from "./dialog";
import type { IAWInfoLineProps } from "~/components/AWInfoLine";
import AWInfoLine from "~/components/AWInfoLine";
import Lottie from "lottie-react";
import VaultLottie from "../../public/images/VaultLottie.json";
import { Button } from "@trident/ui/button";

export default function InsuranceApplicationIllustrationDialog(props: {
  title: string;
  subtitle?: React.ReactNode;
  buttonText?: string;
  buttonCallback?: () => void;
  buttonDisabled?: boolean;
  buttonForm?: string;
  buttonType?: HTMLButtonElement["type"];
  info?: IAWInfoLineProps;
  illustration?: React.ReactNode;
  children?: React.ReactNode;
  disableBackButton?: boolean;
  disableProgressBar?: boolean;
}) {
  return (
    <InsuranceApplicationDialog
      title={props.title}
      disableBackButton={props.disableBackButton ?? false}
      disableProgressBar={props.disableProgressBar ?? false}
    >
      <div className="grid w-full grid-cols-2">
        <div className="flex flex-col justify-between gap-8 border-r-2 border-r-grayscale-6 py-6 pr-6">
          <div className="flex flex-col gap-3">
            {props.subtitle ? (
              <div className="text-xl font-medium text-dark-teal-2">
                {props.subtitle}
              </div>
            ) : null}
            {props.children}
          </div>
          <div className="flex flex-col gap-3">
            {props.info ? <AWInfoLine {...props.info} /> : null}
            {props.buttonCallback ? (
              <Button
                type={props.buttonType}
                form={props.buttonForm}
                onClick={props.buttonCallback}
                disabled={props.buttonDisabled}
                className="max-w-[200px] text-base"
                size="xl"
              >
                {props.buttonText ?? "Next"}
              </Button>
            ) : null}
          </div>
        </div>
        <div className="flex flex-1 items-center justify-center">
          {props.illustration ?? (
            <Lottie
              animationData={VaultLottie}
              style={{ width: 360, height: 360 }}
              loop
              autoplay
            />
          )}
        </div>
      </div>
    </InsuranceApplicationDialog>
  );
}
